import axios from 'axios/dist/axios'
import qs from 'qs';
import  { servicesConstants }  from '../_constants';

export const paymentServices = {
    get,
    disable,
    stop,
    insert,
    getAll
}

const CancelToken = axios.CancelToken;
let cancel;
function get(token, uid) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(`${servicesConstants.URL_SERVER}payments/${uid}`);
}

function disable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.delete(`${servicesConstants.URL_SERVER}payments/${uid}`);
}

function insert(token, score){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'POST',
        baseURL: servicesConstants.URL_SERVER,
        url: `payments`,
        responseType: 'json',
        data: qs.stringify(score),
        headers: { 
            'content-type': 'application/x-www-form-urlencoded'
        }
    };
    return axios(options);
}

function getAll(token, idPlan) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'POST',
        url: `https://app.galaxpay.com.br/webservice/getPaymentBillInfo `,
        responseType: 'json',
        data: {
            "Auth": {
              "galaxId": "5473", 
              "galaxHash": "83Mw5u8988Qj6fZqS4Z8K7LzOo1j28S706R0BeFe"
            }, 
            "Request": {
              "integrationId": idPlan
            }
        },
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function stop(message){
    if (cancel) {
        cancel(message);
    }
}
