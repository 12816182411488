import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { faFileInvoiceDollar, faExclamationTriangle, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alertActions } from '../../_actions';
import Alert from '../../_components/structure/alert';
import { alertConstants, servicesConstants, warningsConstants } from '../../_constants';
import ListItems from '../../_components/structure/listItems';
import Pagination from '../../_components/structure/pagination';
import { mask } from '../../_components/mask';
import { paymentServices } from '../../_services';

import { util } from '../../_components/util';



class Financial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            payments:[], 
            linkCarne: false,
            currentPage: 1, lastPage: 1, search: '', error: false, loading: true, status: true, redirect: false};
        setTimeout(() =>{
            this.loadPayments(1);
        }, 300);

        this.loadPayments = this.loadPayments.bind(this);
        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
    }

    loadPayments(page) {
        const { token, currentUser } = this.props.authentication;
        const { dispatch } = this.props;
        console.log(currentUser);
        this.setState({ loading: true });
        
        paymentServices.getAll(token, currentUser.idPlan).then(res => {
            console.log(res.data);
            if (res.data.type) {
                let payments = [], pay = "--";
                this.setState({linkCarne: res.data.paymentBill.urlCarne});
                console.log(res.data.paymentBill.transactions)
                res.data.paymentBill.transactions.forEach(payment => {
                    let showBtns = true;
                    switch(payment.status) {
                        case 'captured': //Capturada na Operadora
                            pay = (<span className="badge badge-info">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'denied': //Negada na Operadora  
                            pay = (<span className="badge badge-primary">{payment.statusDescription}</span>);
                            showBtns = true;
                            break;
                        case 'processError': //Erro ao processar cobrança    
                            pay = (<span className="badge badge-primary">{payment.statusDescription}</span>);
                            showBtns = true;
                            break;
                        case 'reversed': //Estornada na Operadora     
                            pay = (<span className="badge badge-info">{payment.statusDescription}</span>);
                            showBtns = true;
                            break;
                        case 'notSend': //Ainda não enviada para operadora
                            pay = (<span className="badge badge-info">{payment.statusDescription}</span>);
                            showBtns = true;
                            break;
                        case 'cancel': //Cancelada manualmente
                            pay = (<span className="badge badge-primary">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'reversedAnotherPlace': //Estornada fora do sistema
                            pay = (<span className="badge badge-info">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'payExternal': //Paga fora do sistema
                            pay = (<span className="badge badge-success">Pago</span>);
                            showBtns = false;
                            break;
                        case 'cancelByContract': //Cancelada ao cancelar a cobrança
                            pay = (<span className="badge badge-primary">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'pendingBoleto': //Em aberto
                            pay = (<span className="badge badge-info">{payment.statusDescription}</span>);
                            showBtns = true;
                            break;
                        case 'payedBoleto': //Boleto compensado
                            pay = (<span className="badge badge-success">Pago</span>);
                            showBtns = false;
                            break;
                        case 'lessValueBoleto': //Pago valor menor que o original
                            pay = (<span className="badge badge-primary">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'moreValueBoleto': //Pago valor maior que o original
                            pay = (<span className="badge badge-warning">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'paidDuplicityBoleto': //Pago em duplicidade
                            pay = (<span className="badge badge-warning">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'free': //Isento
                            pay = (<span className="badge badge-success">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'authorized': //Autorizado
                            pay = (<span className="badge badge-info">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'notCompensated': //Boleto baixado por decurso de prazo
                            pay = (<span className="badge badge-primary">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'registerPagExpress': //Registrado na Pagamento Express
                            pay = (<span className="badge badge-info">{payment.statusDescription}</span>);
                            showBtns = false;
                            break;
                        case 'payedPagExpress': //Pago na Pagamento Express
                            pay = (<span className="badge badge-success">Pago</span>);
                            showBtns = false;
                            break;
                        default:
                            pay = (<span className="badge badge-info">Em Aberto</span>);
                            showBtns = true;
                            break;
                    }

                    payments.push({
                        key: payment.internalId,
                        //code: payment.integrationId,
                        infoMain: 0,
                        info:[
                            { name:"Código", value: `#${payment.integrationId}`},
                            { name:"Valor", value: mask.money(parseFloat(payment.value))},
                            { name:"Forma de Pagamento", value: !payment.brand ? "Boleto" : "Cartão" },
                            { name:"Vencimento", value: !payment.brand ? "--" : mask.date(payment.payday) },
                            { name:"Última verificacão", value: mask.datehour(payment.lastUpdateDate)},
                            { name:"Status", value: pay },
                        ],
                        buttons: showBtns ? [
                            {text:'Efetuar Pagamento', icon:faFileInvoiceDollar, color:'success', externalLink: payment.link },
                        ] : null
                    });
                });
                if (payments.length > 0) {
                    this.setState({ payments, error: false, loading: false});
                } else {
                    this.setState({ payments, error: {
                        message: warningsConstants.WA_USERS_NOT_FOUND
                    }, loading: false});
                }
            } else {
                this.setState({ error: res.data, payments: [], currentPage: 1, lastPage: 1, loading: false });
            }
            dispatch(alertActions.clear());


            let newClient = localStorage.getItem('newClient') ? localStorage.getItem('newClient') : util.getUrlParameter(window.location.href, "newClient");
            if (newClient !== "") {
                dispatch(alertActions.success({
                    message: (
                        <div>
                            <h2>Seja Bem Vindo</h2>
                            <p>
                                Aqui você tem acesso aos seus dados no SimpleVida, você pode gerar cupons, adicionar beneficiários e muito mais sobre seu plano.
                            </p>
                        </div>
                    ),
                    eventOnClose: (() => { 
                        localStorage.removeItem('newClient');
                    })
                }));
            }
        }).catch( error => {
            console.log(error);
            dispatch(alertActions.clear());
            if (error.message === servicesConstants.CANCEL_REQUEST) {
                return;
            }
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            this.setState({ error: error, payments: [], currentPage: 1, lastPage: 1, loading: false });
        });
    }

    componentWillUnmount() {
        paymentServices.stop(servicesConstants.CANCEL_REQUEST);
    }

    onChangeStartDate(value) {
        this.setState({ startDate: value });
    }

    onChangeEndDate(value) {
        this.setState({ endDate: value });
    }
    
    submitSearch(e) {
        e.preventDefault();
        this.loadPayments(1);
    }
    render() {
        const { loading, error, payments, lastPage, currentPage, redirect } = this.state;
        if (redirect) {
            return <Redirect push to={this.state.redirect} />;
        }
        
        return (
            <div>
                <section className="m-3">
                    <h2 className="title-page"><FontAwesomeIcon icon={faFileInvoiceDollar} /> Mensalidades</h2>
                    <hr/>
                    { /*
                        linkCarne &&
                        <div className="mb-3 d-flex align-items-center justify-content-end">
                            <Button externalLink={linkCarne} text={"Gerar Carnê"} className="btn-info" icon={faFileInvoiceDollar} color={"success"}/>
                        </div>
                        */
                    }
                    <div className="card">
                        <div className="card-body">
                        { loading && <Alert text="Carregando..." type={alertConstants.LOADING}></Alert> }
                        { (!loading && error) && <Alert text={error.message} type={alertConstants.WARNING}></Alert> }
                        { (!loading && !error) &&  <ListItems items={payments}/> }
                        </div>
                    </div>
                </section>
                <footer>
                    { (!loading && !error && lastPage > 1) && 
                        <Pagination fnChangePage={this.loadUsers} currentPage={currentPage} lastPage={lastPage}/>
                    }   
                </footer>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(Financial);