import React from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from "react-redux";
import { authActions, alertActions } from '../_actions';
import { warningsConstants, alertConstants } from '../_constants';
import { faKey, faInfoCircle, faUser, faUserMd, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import '../_css/login.css';

import TextField from '../_components/forms/textField';
import Button from '../_components/forms/button';
import Dialog from '../_components/structure/dialog';
import Alert from '../_components/structure/alert';
import { util } from '../_components/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Login extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = { 
            login: "", password: "", submitted: false, emailForget: "",
            cpf: "", birthday:"", typeUser: true
        };

        this.submitLogin = this.submitLogin.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);

        let newAgreed = util.getUrlParameter(window.location.href, "newAgreed");
        let newClient = util.getUrlParameter(window.location.href, "newClient");
        if (newAgreed !== "") {
            localStorage.setItem('newAgreed', newAgreed);
            
            let ag = JSON.parse(window.atob(newAgreed));
            setTimeout(() =>{
                this.setState({login: ag.user, password: ag.pass, typeUser: false});
                this.submitLogin();
            }, 300);
        } else if (newClient !== "") {
            localStorage.setItem('newClient', newClient);
            
            let ag = JSON.parse(window.atob(newClient));
            setTimeout(() =>{
                this.setState({cpf: ag.cpf, birthday: ag.birthday, typeUser: true});
                this.submitLogin();
            }, 300);
        }
    }

    submitLogin(e) {
        if (e) {
            e.preventDefault();
        }
        console.log(this.state);
        this.setState({ submitted: true });
        const { dispatch } = this.props;
        
        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
        if (this.state.typeUser) {

            let dt = new Date(this.state.birthday.split("/").reverse().join("-"));
            if (isNaN(dt.getTime())) {
                dispatch(alertActions.warning({iconTitle: faUserMd, icon:faExclamationTriangle, title:"Entrando...", message: warningsConstants.ER_DATE_INVALID}));
                return;
            }

            dispatch(authActions.loginClient(this.state.cpf.replace(/[^\d]+/g, ''), this.state.birthday.split("/").reverse().join("-")));
        } else {
            dispatch(authActions.loginAgreed(this.state.login, this.state.password));
        }
    }

    submitForgetPassword(e) {
        e.preventDefault();
        console.log("ESQUECEU A SENHA");
    }

    onChange = evt => {
        const { dispatch } = this.props;
        if (this.state.submitted) {
            dispatch(alertActions.clear());
            this.setState({ submitted : false });
        }
        this.setState({ [evt.target.name] : evt.target.value });
    }

    onChangeType = evt => {
        this.setState({ typeUser: !this.state.typeUser });
    }

    forgotPassword = evt =>{
        const { dispatch } = this.props;
        const content = (
            <div className="p-2">
                <Alert icon={faInfoCircle} sizeIcon="2x" sizeText="sm" type={alertConstants.LIGHT} 
                text="Para recuperar sua senha informe seu e-mail cadastrado para você redefinir sua senha."/>
                <TextField required={true} name="emailForget" respInvalid="E-mail inválido." pattern={/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/} type="email" placeholder="email@exemplo.com" label="E-mail" />          
            </div>
        );
        dispatch(alertActions.clear());
        dispatch(alertActions.primary({
            iconTitle: faKey, 
            title:"Esqueci minha senha", 
            textBtnPrimary: "Recuperar",
            colorBtnPrimary: "success",
            content,
            eventPrimary: this.submitForgetPassword
        }));
    }

    render() {
        if (this.props.authentication.loggedIn) {
            return <Redirect to='/' />
        }
        const { login, password, cpf, birthday, typeUser } = this.state;
        const { alert } = this.props;

        return (
            <div className="content content-login">
            { (alert.params) && <Dialog /> }
                <div className="d-flex justify-content-center">
                    <section className="col-12 col-sm-10 col-md-6 col-lg-6 col-xl-4 mt-5">
                        <div className="text-center mb-5">
                            <h1 className="hidden">Logo</h1>
                            <img alt="Logo" src="/logo_login.png" height="100"/>
                        </div>
                        <div className="d-flex shadow justify-content-center">
                            <button onClick={e => this.onChangeType(e)} className={`w-50 btn-tab-login p-2 ${typeUser ? 'active' :''}`}><FontAwesomeIcon icon={faUser}/> Beneficiário</button>
                            <button onClick={e => this.onChangeType(e)}  className={`w-50 btn-tab-login p-2 ${!typeUser ? 'active' :''}`}><FontAwesomeIcon icon={faUserMd}/> Profissional</button>
                        </div>
                        { typeUser &&
                        <div className="card pl-4 pr-4 pb-4 pt-0 shadow box-login">
                            <div className="card-body">
                                <form onSubmit={this.submitLogin}>
                                    <TextField className="mb-4" size="lg" value={cpf} mask="cpf" required={true} name="cpf" onChange={e => this.onChange(e)} type="text" id="input-cpf" placeholder="000.000.000-00" label="CPF" />
                                    <TextField className="mb-5" size="lg" value={birthday} mask="date" required={true}  type="text" placeholder="DD/MM/AAAA" name="birthday" onChange={e => this.onChange(e)} id="input-birthday" label="Data de Nascimento" />
                                    <Button type="submit" size="lg" outline={true} text="Entrar" color="success" className="btn-block"/> 
                                </form>
                            </div>
                        </div>}

                        { !typeUser &&
                        <div className="card pl-4 pr-4 pb-4 pt-0 shadow box-login">
                            <div className="card-body">
                                <form onSubmit={this.submitLogin}>
                                    <TextField value={login} required={true} size="lg"  name="login" onChange={e => this.onChange(e)} type="text" id="input-login" placeholder="login" label="Usuário" />
                                    <TextField value={password} required={true} size="lg"  name="password" onChange={e => this.onChange(e)} type="password" id="input-password" placeholder="Sua senha" className="mt-3 mb-3" label="Senha" />
                                    { 
                                        /*<div className="text-right mb-3">
                                        <Button evtClick={this.forgotPassword} type="button"  color="link" text="Esqueci minha senha" className="pr-0"/>
                                        </div>*/
                                    }
                                    <Button type="submit" size="lg" outline={true} text="Entrar" color="success" className="btn-block"/> 
                                </form>
                            </div>
                        </div>}
                    </section>
                </div>
                <footer className="container text-center mt-5">
                <small className="text-muted font-weight-bold">© SimpleVida</small>
                </footer>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { alert, authentication, notification } = state;
    return {
        alert,
        notification,
        authentication
    };
};
  
//const mapDispatchToProps = dispatch => bindActionCreators(authActions, dispatch);
export default connect(mapStateToProps)(Login);