import React from 'react';
import { connect } from "react-redux";
import { history } from "../../_store/history";
import { Link } from "react-router-dom";

import Dialog from '../../_components/structure/dialog';
import Routes from '../../_routes/routes';
import TopBar from '../../_components/structure/topBar';
import Card from '../../_components/structure/card';
import Button from '../../_components/forms/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  
    faWrench, faFileMedicalAlt, faStethoscope, faSignOutAlt, faTicketAlt, faBars, faTimes, faUsers, faFileInvoiceDollar, faLifeRing,
    faEnvelope, faPhone
 } from '@fortawesome/free-solid-svg-icons';
 import {  faWhatsapp } from '@fortawesome/free-brands-svg-icons';


import { warningsConstants } from '../../_constants';
import { alertActions } from '../../_actions';

class Home extends React.Component {

    _isMounted = false;
    
    constructor(props) {
        super(props);

        let len = window.location.pathname.substr(1).indexOf("/");
        const { dispatch } = this.props;

        this.state = { 
            menuOpening: false, 
            menuActive: window.location.pathname.substr(1, len < 0 ? window.location.pathname.length : len)
        };

        this.closeMenu = this.closeMenu.bind(this);
        this.openMenu = this.openMenu.bind(this);

        history.listen((location, action) => {
            let len = location.pathname.substr(1).indexOf("/");
            if (this._isMounted) {
                this.setState({ menuActive: location.pathname.substr(1, len < 0 ? location.pathname.length : len) });
                this.closeMenu();
            }
        });
        dispatch(alertActions.loading({allScreen: true, message: warningsConstants.LD_DEFAULT}));
    } 


    componentDidMount() {
        this._isMounted = true;
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    closeMenu(e) {
        this.setState({ menuOpening: false });
    }

    openMenu(e) {
        e.preventDefault();
        this.setState({ menuOpening: true });
    }

    openSupport = (e) => {
        const { dispatch } = this.props;

        dispatch(alertActions.dialog({
            iconTitle: faLifeRing,
            title: "Precisa de Ajuda?",
            content: (
                <div className="text-center">
                    <h2 className="text-dark text-align-center font-weight-bold mt-3">Estamos aqui para lhe ajudar</h2>
                    <p className="text-muted mt-2 mb-2">Entre em contato conosco quando quiser</p>
                    <div className="d-flex justify-content-around mt-4">
                        <Card>
                            <div className=" text-dark text-center">
                                <h4>Você pode ligar para</h4>
                                <h5 className="text-info font-weight-bold d-block "><FontAwesomeIcon icon={faPhone}/> (85) 3371-4377</h5>
                            </div>
                        </Card>
                        <Card>
                            <div className="text-dark text-center">
                                <h4>Mandar mensagem para</h4>
                                <h5 className="text-success font-weight-bold d-block "><FontAwesomeIcon icon={faWhatsapp}/> (85) 98948-6161</h5>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <Card className="w-100">
                            <div className="text-dark text-center">
                                <h4>Enviar e-mail</h4>
                                <h5 className="text-primary font-weight-bold d-block "><FontAwesomeIcon icon={faEnvelope}/> atendimento.simplevida@outlook.com</h5>
                            </div>
                        </Card>
                    </div>
                </div>
                ),
        }));
    }

    render() {
        const { alert, authentication } = this.props;
        const { menuActive, menuOpening } = this.state;
        return (
        <div>
            { (alert.params) && <Dialog /> }
            <TopBar>
                <img alt="Logo Simple Vida" className="mx-2" src="logo_login.png" width="150"/>
                <div className={"" + (!menuOpening && "menu-closed")} id="box-menu-top">
                    <Link className={"menu-top btn-top-bar "+((menuActive === "dashboard" || menuActive === "") ? "active" : "")} to="/dashboard"><FontAwesomeIcon icon={faStethoscope}/> Início</Link>
                    <Link className={"menu-top btn-top-bar "+(menuActive === "attendance" ? "active" : "")} to="/attendance"><FontAwesomeIcon icon={faTicketAlt}/> Gerar Cupom</Link>
                    <Link className={"menu-top btn-top-bar "+(menuActive === "historico" ? "active" : "") } to="/historico"><FontAwesomeIcon icon={faFileMedicalAlt}/> Histórico</Link>
                    {
                        authentication.currentUser.isHolder &&
                        <Link className={"menu-top btn-top-bar "+(menuActive === "beneficiarios"  ? "active" : "")} to="/beneficiarios"><FontAwesomeIcon icon={faUsers}/> Beneficiários</Link>
                    }
                    {
                        authentication.currentUser.isHolder &&
                        <Link className={"menu-top btn-top-bar "+(menuActive === "mensalidades"  ? "active" : "")} to="/mensalidades"><FontAwesomeIcon icon={faFileInvoiceDollar}/> Mensalidades</Link>
                    }
                    <Link className={"menu-top btn-top-bar "+(menuActive === "meusdados"  ? "active" : "")} to="/meusdados"><FontAwesomeIcon icon={faWrench}/> Meus Dados</Link>
                    <Link className="menu-top btn-top-bar bg-danger text-white" to="/logout"><FontAwesomeIcon icon={faSignOutAlt}/> Sair</Link>

                    <button onClick={this.closeMenu} className="btn-close-menu shadow"><FontAwesomeIcon icon={faTimes} size="2x"/></button>
                </div>
                { !menuOpening && <button onClick={this.openMenu} className="menu-top btn-top-bar btn-open-menu"><FontAwesomeIcon icon={faBars} size="2x"/></button> }
            </TopBar>
            <Routes type={authentication.currentUser.type.code}/>
            <Button evtClick={this.openSupport} text="Precisa de Ajuda ?" icon={faLifeRing} className="btn-callus btn btn-info" />
            <footer className="main text-center">
                <hr className="mb-1"/>
                <small>© SimpleVida</small>
            </footer>
        </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, notification, alert } = state;
    return {
        notification,
        alert,
        authentication
    };
};
export default connect(mapStateToProps)(Home);